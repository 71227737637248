.contacts {
  max-width: 100rem;
  margin: 12rem auto 0 auto;

  background-color: $color-grey-light-2;

  &__tab-container {
    display: flex;
    justify-content: center;
  }

  &__tab:hover {
    transform: translateY(-67%);
  }
  &__tab {
    margin-right: 2.5rem;
    transform: translateY(-50%);

    & span {
      margin-right: 1rem;
      font-weight: 600;
      display: inline-block;
    }

    &--1 {
      background-color: $color-secondary;
    }

    &--1:hover {
      background-color: $color-secondary-darker;
    }
    &--2 {
      background-color: $color-primary;
    }

    &--2:hover {
      background-color: $color-primary-darker;
    }

    &--3 {
      background-color: $color-terciary;
      margin: 0;
    }

    &--3:hover {
      background-color: $color-terciary-darker;
    }

    &--active {
      transform: translateY(-66%);
    }
  }

  &__content {
    display: none;
    // overflow-x: auto;

    /* JUST PRESENTATIONAL */
    font-size: 2.5rem;
    padding: 2.5rem 7rem 6.5rem 7rem;

    &--active {
      display: grid;
      grid-template: auto auto / auto 1fr;
      column-gap: 3rem;
      row-gap: 0.5rem;
    }

    & p {
      grid-column: 2;
      text-align: right;
      white-space: nowrap;

      a:link,
      a:visited {
        padding: 2% 10%;
        border-radius: 5rem;
        // background-color: $color-grey-dark-2;

        text-decoration: none;
        color: $color-grey-dark-1;
      }
    }
  }

  .contacts__header {
    font-size: 4rem;
    font-weight: 500;
    align-self: center;
    text-align: right;
  }

  .contacts__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30rem;
    width: 30rem;
    border-radius: 50%;
    margin: auto;
    grid-row: 1 / span 2;
    img {
      max-width: 70%;
      max-height: 70%;
    }
  }

  &__icon {
    &--1 {
      background-color: $color-secondary-opacity;
      & svg {
        fill: $color-secondary-darker;
      }
    }
    &--2 {
      background-color: $color-primary-opacity;
      & svg {
        fill: $color-primary;
      }
    }
    &--3 {
      background-color: $color-terciary-opacity;
      & svg {
        fill: $color-terciary;
      }
    }
  }
}
