@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600&display=swap";
/* required styles */

.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
	position: absolute;
	left: 0;
	top: 0;
	}

.leaflet-container {
	overflow: hidden;
	}

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
	-webkit-user-select: none;
	   -moz-user-select: none;
	        user-select: none;
	  -webkit-user-drag: none;
	}

/* Prevents IE11 from highlighting tiles in blue */

.leaflet-tile::selection {
	background: transparent;
}

/* Safari renders non-retina tile on retina better with this, but Chrome is worse */

.leaflet-safari .leaflet-tile {
	image-rendering: -webkit-optimize-contrast;
	}

/* hack that prevents hw layers "stretching" when loading new tiles */

.leaflet-safari .leaflet-tile-container {
	width: 1600px;
	height: 1600px;
	-webkit-transform-origin: 0 0;
	}

.leaflet-marker-icon,
.leaflet-marker-shadow {
	display: block;
	}

/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */

/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */

.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
	max-width: none !important;
	max-height: none !important;
	}

.leaflet-container.leaflet-touch-zoom {
	-ms-touch-action: pan-x pan-y;
	touch-action: pan-x pan-y;
	}

.leaflet-container.leaflet-touch-drag {
	-ms-touch-action: pinch-zoom;
	/* Fallback for FF which doesn't support pinch-zoom */
	touch-action: none;
	touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
	-ms-touch-action: none;
	touch-action: none;
}

.leaflet-container {
	-webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
	-webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}

.leaflet-tile {
	filter: inherit;
	visibility: hidden;
	}

.leaflet-tile-loaded {
	visibility: inherit;
	}

.leaflet-zoom-box {
	width: 0;
	height: 0;
	-moz-box-sizing: border-box;
	     box-sizing: border-box;
	z-index: 800;
	}

/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */

.leaflet-overlay-pane svg {
	-moz-user-select: none;
	}

.leaflet-pane         { z-index: 400; }

.leaflet-tile-pane    { z-index: 200; }

.leaflet-overlay-pane { z-index: 400; }

.leaflet-shadow-pane  { z-index: 500; }

.leaflet-marker-pane  { z-index: 600; }

.leaflet-tooltip-pane   { z-index: 650; }

.leaflet-popup-pane   { z-index: 700; }

.leaflet-map-pane canvas { z-index: 100; }

.leaflet-map-pane svg    { z-index: 200; }

.leaflet-vml-shape {
	width: 1px;
	height: 1px;
	}

.lvml {
	behavior: url(#default#VML);
	display: inline-block;
	position: absolute;
	}

/* control positioning */

.leaflet-control {
	position: relative;
	z-index: 800;
	pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
	pointer-events: auto;
	}

.leaflet-top,
.leaflet-bottom {
	position: absolute;
	z-index: 1000;
	pointer-events: none;
	}

.leaflet-top {
	top: 0;
	}

.leaflet-right {
	right: 0;
	}

.leaflet-bottom {
	bottom: 0;
	}

.leaflet-left {
	left: 0;
	}

.leaflet-control {
	float: left;
	clear: both;
	}

.leaflet-right .leaflet-control {
	float: right;
	}

.leaflet-top .leaflet-control {
	margin-top: 10px;
	}

.leaflet-bottom .leaflet-control {
	margin-bottom: 10px;
	}

.leaflet-left .leaflet-control {
	margin-left: 10px;
	}

.leaflet-right .leaflet-control {
	margin-right: 10px;
	}

/* zoom and fade animations */

.leaflet-fade-anim .leaflet-tile {
	will-change: opacity;
	}

.leaflet-fade-anim .leaflet-popup {
	opacity: 0;
	-webkit-transition: opacity 0.2s linear;
	   -moz-transition: opacity 0.2s linear;
	        transition: opacity 0.2s linear;
	}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
	opacity: 1;
	}

.leaflet-zoom-animated {
	-webkit-transform-origin: 0 0;
	    -ms-transform-origin: 0 0;
	        transform-origin: 0 0;
	}

.leaflet-zoom-anim .leaflet-zoom-animated {
	will-change: transform;
	}

.leaflet-zoom-anim .leaflet-zoom-animated {
	-webkit-transition: -webkit-transform 0.25s cubic-bezier(0,0,0.25,1);
	   -moz-transition:    -moz-transform 0.25s cubic-bezier(0,0,0.25,1);
	        transition:         transform 0.25s cubic-bezier(0,0,0.25,1);
	}

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
	-webkit-transition: none;
	   -moz-transition: none;
	        transition: none;
	}

.leaflet-zoom-anim .leaflet-zoom-hide {
	visibility: hidden;
	}

/* cursors */

.leaflet-interactive {
	cursor: pointer;
	}

.leaflet-grab {
	cursor: -webkit-grab;
	cursor:    -moz-grab;
	cursor:         grab;
	}

.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
	cursor: crosshair;
	}

.leaflet-popup-pane,
.leaflet-control {
	cursor: auto;
	}

.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
	cursor: move;
	cursor: -webkit-grabbing;
	cursor:    -moz-grabbing;
	cursor:         grabbing;
	}

/* marker & overlays interactivity */

.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
	pointer-events: none;
	}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive,
svg.leaflet-image-layer.leaflet-interactive path {
	pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
	pointer-events: auto;
	}

/* visual tweaks */

.leaflet-container {
	background: #ddd;
	outline: 0;
	}

.leaflet-container a {
	color: #0078A8;
	}

.leaflet-container a.leaflet-active {
	outline: 2px solid orange;
	}

.leaflet-zoom-box {
	border: 2px dotted #38f;
	background: rgba(255,255,255,0.5);
	}

/* general typography */

.leaflet-container {
	font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
	}

/* general toolbar styles */

.leaflet-bar {
	box-shadow: 0 1px 5px rgba(0,0,0,0.65);
	border-radius: 4px;
	}

.leaflet-bar a,
.leaflet-bar a:hover {
	background-color: #fff;
	border-bottom: 1px solid #ccc;
	width: 26px;
	height: 26px;
	line-height: 26px;
	display: block;
	text-align: center;
	text-decoration: none;
	color: black;
	}

.leaflet-bar a,
.leaflet-control-layers-toggle {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	display: block;
	}

.leaflet-bar a:hover {
	background-color: #f4f4f4;
	}

.leaflet-bar a:first-child {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	}

.leaflet-bar a:last-child {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	border-bottom: none;
	}

.leaflet-bar a.leaflet-disabled {
	cursor: default;
	background-color: #f4f4f4;
	color: #bbb;
	}

.leaflet-touch .leaflet-bar a {
	width: 30px;
	height: 30px;
	line-height: 30px;
	}

.leaflet-touch .leaflet-bar a:first-child {
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
	}

.leaflet-touch .leaflet-bar a:last-child {
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	}

/* zoom control */

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
	font: bold 18px 'Lucida Console', Monaco, monospace;
	text-indent: 1px;
	}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out  {
	font-size: 22px;
	}

/* layers control */

.leaflet-control-layers {
	box-shadow: 0 1px 5px rgba(0,0,0,0.4);
	background: #fff;
	border-radius: 5px;
	}

.leaflet-control-layers-toggle {
	background-image: url("layers.760a0456.png");
	width: 36px;
	height: 36px;
	}

.leaflet-retina .leaflet-control-layers-toggle {
	background-image: url("layers-2x.b7b89169.png");
	background-size: 26px 26px;
	}

.leaflet-touch .leaflet-control-layers-toggle {
	width: 44px;
	height: 44px;
	}

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
	display: none;
	}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
	display: block;
	position: relative;
	}

.leaflet-control-layers-expanded {
	padding: 6px 10px 6px 6px;
	color: #333;
	background: #fff;
	}

.leaflet-control-layers-scrollbar {
	overflow-y: scroll;
	overflow-x: hidden;
	padding-right: 5px;
	}

.leaflet-control-layers-selector {
	margin-top: 2px;
	position: relative;
	top: 1px;
	}

.leaflet-control-layers label {
	display: block;
	}

.leaflet-control-layers-separator {
	height: 0;
	border-top: 1px solid #ddd;
	margin: 5px -10px 5px -6px;
	}

/* Default icon URLs */

.leaflet-default-icon-path {
	background-image: url("marker-icon.3f7d3721.png");
	}

/* attribution and scale controls */

.leaflet-container .leaflet-control-attribution {
	background: #fff;
	background: rgba(255, 255, 255, 0.7);
	margin: 0;
	}

.leaflet-control-attribution,
.leaflet-control-scale-line {
	padding: 0 5px;
	color: #333;
	}

.leaflet-control-attribution a {
	text-decoration: none;
	}

.leaflet-control-attribution a:hover {
	text-decoration: underline;
	}

.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
	font-size: 11px;
	}

.leaflet-left .leaflet-control-scale {
	margin-left: 5px;
	}

.leaflet-bottom .leaflet-control-scale {
	margin-bottom: 5px;
	}

.leaflet-control-scale-line {
	border: 2px solid #777;
	border-top: none;
	line-height: 1.1;
	padding: 2px 5px 1px;
	font-size: 11px;
	white-space: nowrap;
	overflow: hidden;
	-moz-box-sizing: border-box;
	     box-sizing: border-box;

	background: #fff;
	background: rgba(255, 255, 255, 0.5);
	}

.leaflet-control-scale-line:not(:first-child) {
	border-top: 2px solid #777;
	border-bottom: none;
	margin-top: -2px;
	}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
	border-bottom: 2px solid #777;
	}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
	box-shadow: none;
	}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
	border: 2px solid rgba(0,0,0,0.2);
	background-clip: padding-box;
	}

/* popup */

.leaflet-popup {
	position: absolute;
	text-align: center;
	margin-bottom: 20px;
	}

.leaflet-popup-content-wrapper {
	padding: 1px;
	text-align: left;
	border-radius: 12px;
	}

.leaflet-popup-content {
	margin: 13px 19px;
	line-height: 1.4;
	}

.leaflet-popup-content p {
	margin: 18px 0;
	}

.leaflet-popup-tip-container {
	width: 40px;
	height: 20px;
	position: absolute;
	left: 50%;
	margin-left: -20px;
	overflow: hidden;
	pointer-events: none;
	}

.leaflet-popup-tip {
	width: 17px;
	height: 17px;
	padding: 1px;

	margin: -10px auto 0;

	-webkit-transform: rotate(45deg);
	   -moz-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	        transform: rotate(45deg);
	}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
	background: white;
	color: #333;
	box-shadow: 0 3px 14px rgba(0,0,0,0.4);
	}

.leaflet-container a.leaflet-popup-close-button {
	position: absolute;
	top: 0;
	right: 0;
	padding: 4px 4px 0 0;
	border: none;
	text-align: center;
	width: 18px;
	height: 14px;
	font: 16px/14px Tahoma, Verdana, sans-serif;
	color: #c3c3c3;
	text-decoration: none;
	font-weight: bold;
	background: transparent;
	}

.leaflet-container a.leaflet-popup-close-button:hover {
	color: #999;
	}

.leaflet-popup-scrolled {
	overflow: auto;
	border-bottom: 1px solid #ddd;
	border-top: 1px solid #ddd;
	}

.leaflet-oldie .leaflet-popup-content-wrapper {
	-ms-zoom: 1;
	}

.leaflet-oldie .leaflet-popup-tip {
	width: 24px;
	margin: 0 auto;

	-ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
	filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
	}

.leaflet-oldie .leaflet-popup-tip-container {
	margin-top: -1px;
	}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
	border: 1px solid #999;
	}

/* div icon */

.leaflet-div-icon {
	background: #fff;
	border: 1px solid #666;
	}

/* Tooltip */

/* Base styles for the element that has a tooltip */

.leaflet-tooltip {
	position: absolute;
	padding: 6px;
	background-color: #fff;
	border: 1px solid #fff;
	border-radius: 3px;
	color: #222;
	white-space: nowrap;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	pointer-events: none;
	box-shadow: 0 1px 3px rgba(0,0,0,0.4);
	}

.leaflet-tooltip.leaflet-clickable {
	cursor: pointer;
	pointer-events: auto;
	}

.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
	position: absolute;
	pointer-events: none;
	border: 6px solid transparent;
	background: transparent;
	content: "";
	}

/* Directions */

.leaflet-tooltip-bottom {
	margin-top: 6px;
}

.leaflet-tooltip-top {
	margin-top: -6px;
}

.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
	left: 50%;
	margin-left: -6px;
	}

.leaflet-tooltip-top:before {
	bottom: 0;
	margin-bottom: -12px;
	border-top-color: #fff;
	}

.leaflet-tooltip-bottom:before {
	top: 0;
	margin-top: -12px;
	margin-left: -6px;
	border-bottom-color: #fff;
	}

.leaflet-tooltip-left {
	margin-left: -6px;
}

.leaflet-tooltip-right {
	margin-left: 6px;
}

.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
	top: 50%;
	margin-top: -6px;
	}

.leaflet-tooltip-left:before {
	right: 0;
	margin-right: -12px;
	border-left-color: #fff;
	}

.leaflet-tooltip-right:before {
	left: 0;
	margin-left: -12px;
	border-right-color: #fff;
	}

@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
html {
  box-sizing: border-box;
  font-size: 62.5%;
}
@media only screen and (max-width: 61.25em) {
  html {
    font-size: 50%;
  }
}
body {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  line-height: 1.9;
  color: #444;
  background-color: #f9f5f3;
  text-align: justify;
}
h1 {
  font-size: 5.5rem;
  line-height: 1.35;
}
h4 {
  font-size: 2.4rem;
  font-weight: 500;
}
.btn {
  border-radius: 10rem;
  border: none;
  color: #f9f5f3;
  cursor: pointer;
  display: inline-block;
  transition: all 0.2s;
  background-color: #ff9f88;
  font-family: inherit;
  padding: 1.25rem 4.5rem;
}
.btn:hover {
  transform: scale(1.05);
  background-color: #ff9e87;
}
.btn:focus {
  outline: none;
}
.btn > *:first-child {
  margin-right: 1rem;
}
.btn {
  padding: 1.5rem 4rem;
  font-size: 1.6rem;
  font-weight: 500;
  /* all new */
}
.btn svg {
  height: 2.25rem;
  width: 2.25rem;
  fill: currentColor;
}
.btn--text {
  background: none;
  color: #ff9f88;
  font-size: 1.7rem;
  font-weight: 500;
  border-bottom: 1px solid currentColor;
  padding-bottom: 2px;
}
/* not used? */
.heading--2 {
  font-size: 2rem;
  font-weight: 700;
  color: #ff9f88;
  text-transform: uppercase;
  margin-bottom: 2.5rem;
  text-align: center;
}
/*not used yet */
.link:link,
.link:visited {
  color: #918581;
}
.spinner {
  margin: 5rem auto;
  text-align: center;
}
.spinner svg {
  height: 6rem;
  width: 6rem;
  fill: #ff9f88;
  animation: rotate 2s infinite linear;
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.message,
.error {
  max-width: 40rem;
  margin: 0 auto;
  padding: 5rem 4rem;
  display: flex;
}
.message svg,
.error svg {
  height: 3rem;
  width: 3rem;
  fill: #ff9f88;
  transform: translateY(-0.3rem);
}
.message p,
.error p {
  margin-left: 1.5rem;
  font-size: 1.8rem;
  line-height: 1.5;
  font-weight: 600;
}
p {
  color: #d3c7c3;
}
img {
  transition: filter 0.5s;
}
.lazy-img {
  filter: blur(20px);
}
.header {
  padding: 1% 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header__title {
  position: relative;
  text-align: center;
}
.header__img {
  height: 500px;
  width: 900px;
  /* must be removed*/
  object-fit: cover;
  filter: brightness(70%);
  transition: opacity 2s;
}
.header__img.fade {
  opacity: 0;
  transition: opacity 2s;
}
.header__img.img2 {
  position: absolute;
  top: 0;
  left: 0;
  translate: -100% 0;
}
.header__h1 {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #f9f5f3;
  width: 100%;
}
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6rem;
  width: 100%;
  padding: 1% 2%;
  z-index: 100;
  background-color: rgba(255, 159, 136, 0.3);
}
.nav:hover {
  background-color: #db759e;
}
.nav:hover .nav__logo {
  opacity: 1;
}
.nav.sticky {
  position: fixed;
}
.nav__logo {
  height: 4.5rem;
  transition: all 0.2s;
  opacity: 0.6;
}
.nav__langs {
  margin-left: 1rem;
  margin-right: auto;
}
.nav__lang {
  margin-left: 1rem;
  display: inline-block;
  background: none;
  font-size: 1.7rem;
  font-family: inherit;
  font-weight: 500;
  color: #ff9f88;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
}
.nav__lang.active {
  border-bottom: 1px solid currentColor;
  padding-bottom: 2px;
}
.nav__links {
  display: flex;
  align-items: center;
  list-style: none;
}
.nav__item {
  margin-left: 4rem;
}
.nav__link:link, .nav__link:visited {
  font-size: medium;
  font-weight: 400;
  color: inherit;
  text-decoration: none;
  display: block;
  transition: all 0.2s;
}
.nav__link--btn:link, .nav__link--btn:visited {
  padding: 0.8rem 2.5rem;
  border-radius: 3rem;
  background-color: #ff9f88;
  color: #2b2b2b;
}
.nav__link--btn:hover, .nav__link--btn:active {
  color: inherit;
  background-color: #ff9f88;
  color: #444;
}
.section {
  padding: 6rem 3rem 15rem;
  border-top: 2px solid #f2efee;
  margin: auto;
  transition: transform 1s, opacity 1s;
}
.section--hidden {
  opacity: 0;
  transform: translateY(8rem);
}
.section__title {
  max-width: 80rem;
  margin: 0 auto 8rem auto;
  text-align: start;
}
.section__description {
  font-size: 1.7rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #ff9f88;
  margin-bottom: 1rem;
}
.section__header {
  font-size: 4rem;
  line-height: 1.3;
  font-weight: 500;
}
.hide {
  display: none;
}
.hidden {
  visibility: hidden;
  opacity: 0;
}
.chrono {
  transform: translate(0%, -3vh);
  text-align: center;
  vertical-align: middle;
  width: 90%;
  margin: auto;
}
.chrono__year .year {
  padding: 1rem 2rem;
  font-size: 4rem;
  border-radius: 6rem;
  border-color: #ff9f88;
  border-width: 1rem;
}
.chrono__space {
  height: 15rem;
  background-color: transparent;
}
.chrono__space-half {
  height: 7rem;
  background-color: transparent;
}
.chrono__point {
  display: grid;
  grid-template-columns: 5fr 1fr 5fr;
  grid-column: 2;
  justify-items: center;
}
.chrono__point img {
  max-width: 90%;
  box-shadow: 3px 3px 5px 3px #918581;
}
.chrono__point h4 {
  color: #ff9f88;
}
.chrono__point h3 {
  color: #918581;
}
.chrono__middle {
  grid-column: 2;
  fill: #f9f5f3;
  background-color: #ff9f88;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8rem;
  width: 8rem;
  border-radius: 50%;
}
.chrono__left {
  grid-column: 1;
  text-align: right;
  font-size: 3rem;
  margin: auto;
  justify-self: right;
}
.chrono__right {
  grid-column: 3;
  text-align: left;
  font-size: 3rem;
  margin: auto;
  justify-self: left;
}
.chrono__line {
  position: absolute;
  top: 2%;
  left: 48.5%;
  width: 3%;
  height: 95%;
  background-color: #ff9f88;
  z-index: -2;
}
.chrono__line-half {
  position: absolute;
  top: 91%;
  left: 0;
  width: 100%;
  height: 9%;
  background-color: #f9f5f3;
  z-index: -1;
}
.team {
  margin: auto;
  max-width: 90%;
}
.team__tab {
  text-align: center;
  margin: 4% 3%;
  border-bottom: 5px solid #d3c7c3;
  transition: all 0.2s;
}
.team__tab--active {
  border-color: #ff9f88;
}
.team__tab .photo {
  border: 1px solid #d3c7c3;
  width: 240px;
  height: 280px;
  object-fit: cover;
  box-shadow: 3px 3px 5px 3px #918581;
}
.team__tab-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.team__content {
  display: none;
  opacity: 0;
  font-size: medium;
  width: max(60%, 400px);
  margin: auto;
  padding-top: 2rem;
  transition: all 0.2s;
}
.team__content--active {
  display: block;
  opacity: 1;
}
.features {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  margin: 0 2vw;
}
.features__header {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: start;
}
.features__img {
  width: 90%;
  object-fit: cover;
  box-shadow: 3px 3px 5px 3px #918581;
}
.features__feature {
  align-self: center;
  justify-self: center;
  width: 70%;
  font-size: 1.5rem;
}
.features__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 159, 136, 0.3);
  height: 5.5rem;
  width: 5.5rem;
  border-radius: 50%;
  margin-bottom: 2rem;
}
.features__icon svg {
  height: 2.5rem;
  width: 2.5rem;
  fill: #ff9f88;
}
.btn--text {
  display: inline-block;
  background: none;
  font-size: 1.7rem;
  font-family: inherit;
  font-weight: 500;
  color: #ff9f88;
  border: none;
  border-bottom: 1px solid currentColor;
  padding-bottom: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
#map {
  flex: 1;
  height: 50vh;
  min-height: 250px;
  max-height: 600px;
  width: 50vw;
  background-color: #f3f3f3;
  margin: auto;
  z-index: 1;
}
.map__popup .leaflet-popup-content {
  background-color: #f9f5f3;
  text-align: center;
}
.map__popup .leaflet-popup-content-wrapper {
  background-color: #f9f5f3;
}
.map__popup a {
  font-size: 110%;
  color: #ff9f88;
}
.address {
  padding-top: 1.5rem;
  display: flex;
}
.address p {
  margin: auto;
  width: 50vw;
  font-size: 1.5rem;
}
.slider {
  max-width: 100rem;
  height: 50rem;
  margin: 0 auto;
  position: relative;
  /* IN THE END */
  overflow: hidden;
}
.slider__btn {
  position: absolute;
  top: 50%;
  z-index: 10;
  border: none;
  background: #f2efee;
  font-family: inherit;
  fill: #444;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5.5rem;
  width: 5.5rem;
  cursor: pointer;
}
.slider__btn--left {
  left: 6%;
  transform: translate(-50%, -50%);
}
.slider__btn--right {
  right: 6%;
  transform: translate(50%, -50%);
}
.slider__btn svg {
  padding: 15%;
}
.slide {
  position: absolute;
  top: 0;
  width: 100%;
  height: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* THIS creates the animation! */
  transition: transform 1s;
}
.slide > img {
  /* Only for images that have different size than slide */
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.dots {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}
.dots__dot {
  border: none;
  background-color: #d3c7c3;
  opacity: 0.7;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  margin-right: 1.75rem;
  cursor: pointer;
  transition: all 0.2s;
  /* Only necessary when overlying images */
  /* box-shadow: 0 0.6rem 1.5rem rgba(0, 0, 0, 0.7); */
}
.dots__dot:last-child {
  margin: 0;
}
.dots__dot--active {
  background-color: #918581;
  opacity: 1;
}
.testimonial {
  width: 65%;
  position: relative;
}
.testimonial::before {
  content: "“";
  position: absolute;
  top: -5.7rem;
  left: -6.8rem;
  line-height: 1;
  font-size: 20rem;
  font-family: inherit;
  color: #ff9f88;
  z-index: -1;
}
.testimonial__header {
  font-size: 2.25rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
}
.testimonial__text {
  font-size: 1.7rem;
  margin-bottom: 3.5rem;
  color: #d3c7c3;
}
.testimonial__author {
  margin-left: 3rem;
  font-style: normal;
  display: grid;
  grid-template-columns: 6.5rem 1fr;
  column-gap: 2rem;
}
.testimonial__photo {
  grid-row: 1/span 2;
  width: 6.5rem;
  border-radius: 50%;
}
.testimonial__name {
  font-size: 1.7rem;
  font-weight: 500;
  align-self: end;
  margin: 0;
}
.testimonial__location {
  font-size: 1.5rem;
}
.section__title--testimonials {
  margin-bottom: 4rem;
}
.faq {
  display: block;
  list-style: none;
  margin: auto;
  width: 70vw;
}
.faq__question {
  border: 2px solid #d3c7c3;
  margin: 2rem auto;
}
.faq__header {
  padding: 1rem 2rem;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  font-size: 3rem;
  text-align: left;
  color: #918581;
}
.faq__header span {
  background-color: #f2efee;
  margin: auto 2% auto auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  border: 2px solid #918581;
}
.faq__header span svg {
  fill: #918581;
  height: 2rem;
  width: 2rem;
}
.faq__answer {
  display: none;
  margin: auto 5% 0;
  padding: 2%;
  font-size: 2rem;
  border-top: 4px solid #f2efee;
}
.faq__answer a {
  font-size: 2rem;
}
.faq__answer--active {
  display: block;
}
.footer {
  padding: 10rem 3rem 0.5rem;
  background-color: #37383d;
  /* see components */
}
.footer__nav {
  list-style: none;
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
}
.footer__item {
  margin-right: 4rem;
  display: flex;
}
.footer__link {
  font-size: 1.8rem;
  color: #eee;
  text-decoration: none;
  margin: auto;
  text-align: start;
}
.footer__logo {
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.2rem;
  width: 4.2rem;
  border-radius: 50%;
  border: 2px solid #37383d;
  fill: #37383d;
  margin: auto 8%;
}
.footer__logo svg {
  padding: 15%;
}
.footer__copyright {
  font-size: 0.65vw;
  color: #aaa;
  text-align: center;
}
.footer__copyright .footer__link {
  font-size: 0.65vw;
}
.contacts {
  max-width: 100rem;
  margin: 12rem auto 0 auto;
  background-color: #f2efee;
}
.contacts__tab-container {
  display: flex;
  justify-content: center;
}
.contacts__tab:hover {
  transform: translateY(-67%);
}
.contacts__tab {
  margin-right: 2.5rem;
  transform: translateY(-50%);
}
.contacts__tab span {
  margin-right: 1rem;
  font-weight: 600;
  display: inline-block;
}
.contacts__tab--1 {
  background-color: #ffc788;
}
.contacts__tab--1:hover {
  background-color: #ffc787;
}
.contacts__tab--2 {
  background-color: #ff9f88;
}
.contacts__tab--2:hover {
  background-color: #ff9e87;
}
.contacts__tab--3 {
  background-color: #db759e;
  margin: 0;
}
.contacts__tab--3:hover {
  background-color: #db749d;
}
.contacts__tab--active {
  transform: translateY(-66%);
}
.contacts__content {
  display: none;
  /* JUST PRESENTATIONAL */
  font-size: 2.5rem;
  padding: 2.5rem 7rem 6.5rem 7rem;
}
.contacts__content--active {
  display: grid;
  grid-template: auto auto/auto 1fr;
  column-gap: 3rem;
  row-gap: 0.5rem;
}
.contacts__content p {
  grid-column: 2;
  text-align: right;
  white-space: nowrap;
}
.contacts__content p a:link,
.contacts__content p a:visited {
  padding: 2% 10%;
  border-radius: 5rem;
  text-decoration: none;
  color: #444;
}
.contacts .contacts__header {
  font-size: 4rem;
  font-weight: 500;
  align-self: center;
  text-align: right;
}
.contacts .contacts__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30rem;
  width: 30rem;
  border-radius: 50%;
  margin: auto;
  grid-row: 1/span 2;
}
.contacts .contacts__icon img {
  max-width: 70%;
  max-height: 70%;
}
.contacts__icon--1 {
  background-color: rgba(255, 199, 136, 0.3);
}
.contacts__icon--1 svg {
  fill: #ffc787;
}
.contacts__icon--2 {
  background-color: rgba(255, 159, 136, 0.3);
}
.contacts__icon--2 svg {
  fill: #ff9f88;
}
.contacts__icon--3 {
  background-color: rgba(219, 117, 158, 0.3);
}
.contacts__icon--3 svg {
  fill: #db759e;
}
.open-hours {
  font-size: small;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
  width: 80%;
  list-style: none;
  transform: translate(0, -3rem);
}
.open-hours .day {
  white-space: nowrap;
  padding: 1%;
  margin: 0 auto;
  width: max-content;
}
.open-hours .day.today {
  font-weight: bold;
}
.home__body {
  display: grid;
  grid-template: 2fr 1fr/auto auto;
}
.home__body__img {
  max-width: 90%;
  grid-column: 2;
  grid-row: 1/span 2;
  margin: auto;
  box-shadow: 3px 3px 5px 3px #918581;
}
.home__body .btn--text {
  grid-column: 1;
}
.home__body .section__header {
  margin: auto auto 0 0;
}
.agreements {
  margin: auto;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  max-width: 75vw;
}
.agreements__item {
  margin: auto;
  width: 200px;
  font-size: large;
  font-weight: 700;
  text-align: left;
  color: #444;
}
.agreements__img {
  height: 100px;
  width: 200px;
  object-fit: contain;
}
#section--about .section__header {
  font-size: large;
  color: #918581;
  text-align: justify;
  margin: auto;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 100;
  transition: all 0.2s;
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 60rem;
  background-color: #ff9f88;
  padding: 5rem 6rem;
  box-shadow: 0 4rem 6rem rgba(0, 0, 0, 0.3);
  z-index: 1000;
  transition: all 0.2s;
}
.modal__header {
  font-size: 3.25rem;
  margin-bottom: 4.5rem;
  line-height: 1.5;
}
.modal__form {
  margin: 0 3rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  gap: 2.5rem;
}
.modal__form label {
  font-size: 1.7rem;
  font-weight: 500;
}
.modal__form input {
  font-size: 1.7rem;
  padding: 1rem 1.5rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
}
.modal__form button {
  grid-column: 1/span 2;
  justify-self: center;
  margin-top: 1rem;
}
.btn--close-modal {
  font-family: inherit;
  color: inherit;
  position: absolute;
  top: 0.5rem;
  right: 2rem;
  font-size: 4rem;
  cursor: pointer;
  border: none;
  background: none;
}
.hidden {
  visibility: hidden;
  opacity: 0;
}
/*# sourceMappingURL=index.465451ac.css.map */
