.section {
  padding: 6rem 3rem 15rem;
  border-top: 2px solid $color-grey-light-2;
  margin: auto;

  transition: transform 1s, opacity 1s;

  &--hidden {
    opacity: 0;
    transform: translateY(8rem);
  }

  &__title {
    max-width: 80rem;
    margin: 0 auto 8rem auto;
    text-align: start;
  }

  &__description {
    font-size: 1.7rem;
    font-weight: 600;
    text-transform: uppercase;
    color: $color-primary;
    margin-bottom: 1rem;
  }

  &__header {
    font-size: 4rem;
    line-height: 1.3;
    font-weight: 500;
  }
}

.hide {
  display: none;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}
