.footer {
  padding: 10rem 3rem 0.5rem;
  background-color: $color-grey-dark-0;

  &__nav {
    list-style: none;
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;
  }

  &__item {
    margin-right: 4rem;
    display: flex;
  }

  /* see components */
  &__link {
    font-size: 1.8rem;
    color: $color-grey-light-0;
    text-decoration: none;
    margin: auto;
    text-align: start;
  }

  &__logo {
    $color: $color-grey-dark-0;
    background-color: $color-grey-light-0;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.2rem;
    width: 4.2rem;
    border-radius: 50%;
    border: 2px solid $color;
    fill: $color;
    margin: auto 8%;
    svg {
      padding: 15%;
    }
  }

  &__copyright {
    font-size: 0.65vw;
    color: #aaa;
    text-align: center;
  }
  &__copyright &__link {
    font-size: 0.65vw;
  }
}
