.faq {
  display: block;
  list-style: none;
  margin: auto;
  width: 70vw;

  &__question {
    border: 2px solid $color-grey-light-3;
    margin: 2rem auto;
  }

  &__header {
    padding: 1rem 2rem;
    cursor: pointer;
    display: flex;
    font-weight: 500;
    font-size: 3rem;
    text-align: left;
    color: $color-grey-dark-2;

    & span {
      background-color: $color-grey-light-2;
      margin: auto 2% auto auto;

      display: flex;
      align-items: center;
      justify-content: center;
      height: 3rem;
      width: 3rem;
      border-radius: 50%;
      border: 2px solid $color-grey-dark-2;
      & svg {
        fill: $color-grey-dark-2;
        height: 2rem;
        width: 2rem;
      }
    }
  }

  &__answer {
    display: none;
    margin: auto 5% 0;
    padding: 2%;
    font-size: 2rem;
    border-top: 4px solid $color-grey-light-2;
    // transition: $transition-time;
    a {
      font-size: 2rem;
    }

    &--active {
      display: block;
    }
  }
}
