$map-width: 50vw;

#map {
  flex: 1;
  height: 50vh;
  min-height: 250px;
  max-height: 600px;
  width: $map-width;
  background-color: #f3f3f3;
  margin: auto;
  z-index: 1;
}

.map__popup {
  & .leaflet-popup-content {
    background-color: $color-grey-light-1;
    text-align: center;
    &-wrapper {
      background-color: $color-grey-light-1;
    }
  }
  & a {
    font-size: 110%;
    color: $color-primary;
  }
}
.address {
  padding-top: 1.5rem;
  display: flex;

  p {
    margin: auto;
    width: $map-width;
    font-size: 1.5rem;
  }
}
