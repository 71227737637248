.slider {
  max-width: 100rem;
  height: 50rem;
  margin: 0 auto;
  position: relative;

  /* IN THE END */
  overflow: hidden;

  &__btn {
    position: absolute;
    top: 50%;
    z-index: 10;

    border: none;
    background: $color-grey-light-2;
    font-family: inherit;
    fill: $color-grey-dark-1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5.5rem;
    width: 5.5rem;
    cursor: pointer;

    &--left {
      left: 6%;
      transform: translate(-50%, -50%);
    }

    &--right {
      right: 6%;
      transform: translate(50%, -50%);
    }
    svg {
      padding: 15%;
    }
  }
}

.slide {
  position: absolute;
  top: 0;
  width: 100%;
  height: 50rem;

  display: flex;
  align-items: center;
  justify-content: center;

  /* THIS creates the animation! */
  transition: transform 1s;

  & > img {
    /* Only for images that have different size than slide */
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.dots {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;

  &__dot {
    border: none;
    background-color: $color-grey-light-3;
    opacity: 0.7;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    margin-right: 1.75rem;
    cursor: pointer;
    transition: $transition-time;

    /* Only necessary when overlying images */
    /* box-shadow: 0 0.6rem 1.5rem rgba(0, 0, 0, 0.7); */

    &:last-child {
      margin: 0;
    }

    &--active {
      background-color: $color-grey-dark-2;
      opacity: 1;
    }
  }
}
