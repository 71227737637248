.features {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  margin: 0 2vw;
  // margin: auto;

  // display: flex;
  // flex-wrap: wrap;

  &__header {
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: start;
  }

  &__img {
    width: 90%;
    object-fit: cover;
    box-shadow: 3px 3px 5px 3px $color-grey-dark-2;
    // flex: 45%;
  }

  &__feature {
    align-self: center;
    justify-self: center;
    width: 70%;
    font-size: 1.5rem;

    // flex: 45%;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-primary-opacity;
    height: 5.5rem;
    width: 5.5rem;
    border-radius: 50%;
    margin-bottom: 2rem;

    & svg {
      height: 2.5rem;
      width: 2.5rem;
      fill: $color-primary;
    }
  }
}
.btn--text {
  display: inline-block;
  background: none;
  font-size: 1.7rem;
  font-family: inherit;
  font-weight: 500;
  color: $color-primary;
  border: none;
  border-bottom: 1px solid currentColor;
  padding-bottom: 2px;
  cursor: pointer;
  transition: all 0.3s;
}
