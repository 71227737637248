$color-primary: #ff9f88; //#5ec576;
$color-secondary: #ffc788; // #ffcb03;
$color-terciary: #db759e; // #ff585f;

$darken-value: 0.2;
$color-primary-darker: darken($color-primary, $darken-value); //#4bbb7d;
$color-secondary-darker: darken($color-secondary, $darken-value); //#ffbb00;
$color-terciary-darker: darken($color-terciary, $darken-value); //#fd424b;

$opacity-color: 0.3;
$color-primary-opacity: rgba($color-primary, $opacity-color);
$color-secondary-opacity: rgba($color-secondary, $opacity-color);
$color-terciary-opacity: rgba($color-terciary, $opacity-color);

$color-grad-primary-1: lighten($color-primary, $darken-value); //#9be15d;
$color-grad-primary-2: darken($color-primary-darker, $darken-value); //#39b385;
$color-grad-secondary-1: lighten($color-secondary, $darken-value); //#ffcb03;
$color-grad-secondary-2: darken(
  $color-secondary-darker,
  $darken-value
); //#ffb003;

// text footer
$color-grey-light-0: #eee;
// Light background
$color-grey-light-1: #f9f5f3;
// Light lines
$color-grey-light-2: #f2efee;
// Light text (placeholder)
$color-grey-light-3: #d3c7c3;
// footer
$color-grey-dark-0: #37383d;
// Normal text
$color-grey-dark-1: #444;
// Lighter text
$color-grey-dark-2: #918581;

$gradient-primary: linear-gradient(
  to right bottom,
  $color-grad-primary-1,
  $color-grad-primary-2
);

$bp-large: 78.15em; // 1250px
$bp-medium: 61.25em; // 980px
$bp-small: 37.5em; // 600px
$bp-smallest: 31.25em; // 500px

$transition-time: all 0.2s;
$nav-heigth: 6rem;

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;

  @media only screen and (max-width: $bp-medium) {
    font-size: 50%; // don't know about this one
  }
}

body {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  line-height: 1.9;
  color: $color-grey-dark-1;
  //   background-size: cover;
  //   background-repeat: no-repeat;
  //   min-height: calc(100vh - 2 * 4vw);
  //   background-image: $gradient;
  background-color: $color-grey-light-1;
  text-align: justify;
}

h1 {
  font-size: 5.5rem;
  line-height: 1.35;
}

h4 {
  font-size: 2.4rem;
  font-weight: 500;
}
