.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $nav-heigth;
  width: 100%;
  padding: 1% 2%;
  z-index: 100;
  background-color: $color-primary-opacity;

  &:hover {
    background-color: $color-terciary;

    .nav__logo {
      opacity: 1;
    }
  }

  &.sticky {
    position: fixed;
    // background-color: $color-primary-opacity;
  }

  &__logo {
    height: 4.5rem;
    transition: $transition-time;
    opacity: calc(2 * $opacity-color);
  }

  &__langs {
    // justify-content: flex-start;
    margin-left: 1rem;
    margin-right: auto;
  }
  &__lang {
    margin-left: 1rem;
    display: inline-block;
    background: none;
    font-size: 1.7rem;
    font-family: inherit;
    font-weight: 500;
    color: $color-primary;
    border: none;
    cursor: pointer;
    transition: all 0.3s;
    &.active {
      border-bottom: 1px solid currentColor;
      padding-bottom: 2px;
    }
  }

  &__links {
    display: flex;
    align-items: center;
    list-style: none;
  }

  &__item {
    margin-left: 4rem;
  }

  &__link {
    &:link,
    &:visited {
      font-size: medium;
      font-weight: 400;
      color: inherit;
      text-decoration: none;
      display: block;
      transition: $transition-time;
    }

    &--btn {
      &:link,
      &:visited {
        padding: 0.8rem 2.5rem;
        border-radius: 3rem;
        background-color: $color-primary;
        color: darken($color: rgba($color-grey-dark-1, 1), $amount: 10);
      }

      &:hover,
      &:active {
        color: inherit;
        background-color: $color-primary;
        color: $color-grey-dark-1;
      }
    }
  }
}
