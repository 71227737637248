.agreements {
  margin: auto;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  max-width: 75vw;
  $height: 100px;
  $width: 200px;
  &__item {
    margin: auto;
    width: $width;
    font-size: large;
    font-weight: 700;
    text-align: left;
    color: $color-grey-dark-1;
  }
  &__img {
    height: $height;
    width: $width;
    object-fit: contain;
    // box-shadow: 3px 3px 5px 3px $color-grey-dark-2;
  }
}
