.header {
  padding: 1% 3rem 0;
  // height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    position: relative;
    text-align: center;
  }

  &__img {
    // transform: translateY(-6rem);
    height: 500px;
    width: 900px; /* must be removed*/
    object-fit: cover;
    // margin-top: $nav-heigth;
    filter: brightness(70%);

    $fade-time: 2s;
    // opacity: 1;
    transition: opacity $fade-time;
    &.fade {
      opacity: 0;
      transition: opacity $fade-time;
    }
    &.img2 {
      position: absolute;
      top: 0;
      left: 0;
      translate: -100% 0;
    }
  }

  &__h1 {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $color-grey-light-1;
    width: 100%;
    // margin-top: auto;
    // margin-bottom: 2%;
  }
}
