.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 100;
  transition: $transition-time;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 60rem;
  background-color: $color-primary;
  padding: 5rem 6rem;
  box-shadow: 0 4rem 6rem rgba(0, 0, 0, 0.3);
  z-index: 1000;
  transition: $transition-time;

  &__header {
    font-size: 3.25rem;
    margin-bottom: 4.5rem;
    line-height: 1.5;
  }

  &__form {
    margin: 0 3rem;
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    gap: 2.5rem;

    & label {
      font-size: 1.7rem;
      font-weight: 500;
    }

    & input {
      font-size: 1.7rem;
      padding: 1rem 1.5rem;
      border: 1px solid #ddd;
      border-radius: 0.5rem;
    }

    & button {
      grid-column: 1 / span 2;
      justify-self: center;
      margin-top: 1rem;
    }
  }
}

.btn--close-modal {
  font-family: inherit;
  color: inherit;
  position: absolute;
  top: 0.5rem;
  right: 2rem;
  font-size: 4rem;
  cursor: pointer;
  border: none;
  background: none;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}
