.team {
  margin: auto;
  max-width: 90%;

  &__tab {
    text-align: center;
    margin: 4% 3%;
    border-bottom: 5px solid $color-grey-light-3;
    transition: $transition-time;

    &--active {
      border-color: $color-primary;
    }

    & .photo {
      border: 1px solid $color-grey-light-3;
      width: 240px;
      height: 280px;
      object-fit: cover;
      box-shadow: 3px 3px 5px 3px $color-grey-dark-2;
    }

    &-container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  &__content {
    // &-container {
    //   overflow: hidden;
    //   display: inline-flex;
    // }

    // position: absolute;
    display: none;
    opacity: 0;
    font-size: medium;
    width: max(60%, 400px);
    // min-width: 500px;
    // max-width: 100%;
    margin: auto;
    padding-top: 2rem;
    transition: $transition-time;
    &--active {
      display: block;
      opacity: 1;
    }
  }
}
