%btn {
  //   background-image: $gradient;
  border-radius: 10rem;
  border: none;
  //   text-transform: uppercase;
  color: $color-grey-light-1;
  cursor: pointer;
  display: inline-block;
  //   align-items: center;
  transition: $transition-time;

  background-color: $color-primary;
  font-family: inherit;
  padding: 1.25rem 4.5rem;

  &:hover {
    transform: scale(1.05);

    background-color: $color-primary-darker;
  }

  &:focus {
    outline: none;
  }

  & > *:first-child {
    margin-right: 1rem;
  }
}

.btn {
  @extend %btn;

  padding: 1.5rem 4rem;
  font-size: 1.6rem;
  font-weight: 500;

  svg {
    height: 2.25rem;
    width: 2.25rem;
    fill: currentColor;
  }

  /* all new */
  &--text {
    background: none;
    color: $color-primary;
    font-size: 1.7rem;
    font-weight: 500;
    border-bottom: 1px solid currentColor;
    padding-bottom: 2px;
  }
}

/* not used? */
.heading--2 {
  font-size: 2rem;
  font-weight: 700;
  color: $color-primary;
  text-transform: uppercase;
  margin-bottom: 2.5rem;
  text-align: center;
  // transform: skewY(-3deg);
}

/*not used yet */
.link:link,
.link:visited {
  color: $color-grey-dark-2;
}

.spinner {
  margin: 5rem auto;
  text-align: center;

  svg {
    height: 6rem;
    width: 6rem;
    fill: $color-primary;
    animation: rotate 2s infinite linear;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.message,
.error {
  max-width: 40rem;
  margin: 0 auto;
  padding: 5rem 4rem;

  display: flex;

  svg {
    height: 3rem;
    width: 3rem;
    fill: $color-primary;
    transform: translateY(-0.3rem);
  }

  p {
    margin-left: 1.5rem;
    font-size: 1.8rem;
    line-height: 1.5;
    font-weight: 600;
  }
}

p {
  color: $color-grey-light-3;
}

img {
  transition: filter 0.5s;
}

.lazy-img {
  filter: blur(20px);
}
